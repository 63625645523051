import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 進階服務設定

const routes: Array<RouteConfig> = [

    {
		path: '/service',
		component: () => import(/* webpackChunkName: "service" */ '@/views/service/Index.vue'),
		meta: {
			root: true,
		},
	},
	{
		path: '/service/bgf',
		component: () => import(/* webpackChunkName: "service" */ '@/views/service/buyandgetfree/Index.vue'),
	},
	{
		path: '/service/bgf/add',
		component: () => import(/* webpackChunkName: "service" */ '@/views/service/buyandgetfree/Edit.vue'),
	},
	{
		path: '/service/bgf/edit/:id',
		props: true,
		component: () => import(/* webpackChunkName: "service" */ '@/views/service/buyandgetfree/Edit.vue'),
	},
	{
		path: '/service/pointRecord',
		component: () => import(/* webpackChunkName: "service" */ '@/views/service/PointRecord.vue'),
	},
];

export default routes;
