
	/* eslint-disable @typescript-eslint/no-explicit-any */
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";

	declare const Slideout: any;

	@Component
	export default class Menu extends Vue {
		@Prop(Boolean) public disabled: boolean = false;

		@Watch("enabled") onEnableChanged(value: boolean): void {
			if(value) this.start();
			else this.end();
		}

		private slideout: any;
		private panel!: HTMLElement;

		private get enabled(): boolean {
			return !this.$store.state.hideNav && !this.$store.state.hideMenu;
		}

		public init(panel: HTMLElement): void {
			this.panel = panel;
			this.start();
			if(!this.enabled) this.end();
			let el = this.$el as HTMLElement;
			el.style.display = "block";
			el.addEventListener("click", e => {
				if(e.target instanceof HTMLAnchorElement && this.slideout) this.slideout.close();
			});
		}

		public start(): void {
			this.slideout = new Slideout({
				panel: this.panel,
				menu: this.$el,
				itemToMove: "menu",
				side: "left",
				grabWidth: 25,
			});
		}

		private end(): void {
			if(!this.slideout) return;
			this.slideout.destroy();
			this.slideout = null;
			this.panel.removeChild(this.panel.firstChild!);
		}

		public toggle(): void {
			if(this.slideout) this.slideout.toggle();
		}

		public closeIfOpen(): boolean {
			if(!this.slideout || !this.slideout.isOpen()) return false;
			this.slideout.close();
			return true;
		}
	}
