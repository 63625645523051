
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { MerchantKioskType } from "@common/models/kiosk";
	import TitleModal from "@/components/modal/TitleModal.vue";
	import QRModal from "@/components/modal/QRModal.vue";
	import { resetState } from "../Index.vue";
	import WifiModal from "../modal/WifiModal.vue";
	import CoinSlot from "./CoinSlot.vue";

	import type { TagModel, MerchantKioskDetailModel } from "@common/models/kiosk";

	@Component({ components: { CoinSlot, QRModal, TitleModal, WifiModal } })
	export default class extends Vue {
		$refs!: {
			modal: TitleModal;
			qr: QRModal;
			wifi: WifiModal;
		};

		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		@Prop() public data!: MerchantKioskDetailModel;

		protected tags: TagModel[] = [];

		beforeMount(): void {
			post<TagModel[]>('/api/tag').then(v => this.tags = v);
		}

		protected get title(): string {
			if(!this.data) return "";
			return this.data.Kiosk.Name;
		}

		protected get admin(): boolean {
			return this.data?.Type == MerchantKioskType.admin;
		}

		protected edit(): void {
			var m = this.$refs.modal;
			m.model = this.data;
			m.show();
		}

		protected async coin(): Promise<void> {
			if(!await this.confirm('確定要遠端投幣嗎？', '遠端投幣', 'warning')) return;
			await post('/api/Device/Coin', { Id: this.data!.Id });
			await this.alert('目前僅發出投幣指令，\n投幣是否成功尚需現場人員確認。', '指令已發送', 'success');
			this.$emit('coin');
		}

		protected async reset(): Promise<void> {
			try {
				if(!await this.confirm('<ol class="pl-3 text-left"><li>按下重置後，設備將解除帳號綁定<li>此設備即可設定在其它帳號/機台上<li>營收報表中仍可查閱機台歷史營收<li>當設備解除帳號綁定時，原綁定的共享帳號皆會失去權限</ol>', '解除帳號綁定', 'danger', '重置')) return;
				await post('/api/Device/Reset', { Id: this.data.Id });
				resetState();
				this.$router.back();
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "解除帳號綁定失敗", "error");
			}
		}

		protected async cancelShare(): Promise<void> {
			try {
				if(!await this.confirm('確定要取消裝置共享嗎？這將會使這台裝置從您的列表中永久移除。', '取消共享', 'warning')) return;
				await post('/api/Device/CancelShare', { Id: this.data.Id });
				resetState();
				this.$router.back();
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "取消失敗", "error");
			}
		}

		protected qr(): void {
			this.$refs.qr.show(this.data.Kiosk.HardwareCode, this.data.Kiosk.Url);
		}

		protected wifi(): void {
			this.$refs.wifi.show(this.data.Kiosk.HardwareCode);
		}
	}
