
	/* eslint-disable @typescript-eslint/no-magic-numbers */
	import { Component, Vue, Watch } from "vue-property-decorator";

	import { now } from "@common/date";
	import TitleModal from "@/components/modal/TitleModal.vue";
	import FilterModal from "@/components/modal/FilterModal.vue";
	import Sorter, { sort } from "@/components/Sorter.vue";
	import { setScroll } from "@/views/setScroll";
	import { sum } from "@/views/utility";
	import { toIndexQuery, newQuery } from "@/models/Report";
	import Base from "../Base";
	import Revenue from "./components/Revenue.vue";
	import ExcelModal from "./modal/ExcelModal.vue";
	import Kiosk from "./components/Kiosk.vue";
	import Summary from "./components/Summary.vue";
	import { cache as DetailCache } from "./Detail.vue";
	import { threeMonthAgo } from "./util";
	import type { Selector } from "@/components/Sorter.vue";

	import type { Query, ReportIndexModel, ReportIndexBundle, ReportModel } from "@/models/Report";
	import type { TagModel } from "@common/models/kiosk";
	import type { NavigationGuardNext, Route } from "vue-router";

	let cache: { query: Query, state?: { scroll: number, data: ReportIndexBundle, time: string } } | null = null;

	export function resetState(): void {
		if(cache) cache.state = undefined;
	}

	export function resetCache(): void {
		cache = null;
		DetailCache.query = null;
	}

	const TIMEOUT = 120000;

	@Component({ components: { Kiosk, Summary, FilterModal, ExcelModal, TitleModal, Sorter, Revenue } })
	export default class extends Base {
		$refs!: {
			select: HTMLSelectElement,
			modal: TitleModal,
			filter: FilterModal,
			excel: ExcelModal,
		};

		protected data: ReportIndexBundle | null = null;
		protected summary: ReportModel[] = [];
		protected query: Query = newQuery();
		protected tags: TagModel[] = [];

		protected sorting: [number, boolean] = [0, true];
		protected sortingOption: string[] = [
			"名稱", "連線狀態", "啟動次數", "出貨數", "累保金額", "毛利", "毛利率",
		];
		protected sortingSelectors: Selector<ReportIndexModel>[] = [
			(a: ReportIndexModel): string => a.Name,
			(a: ReportIndexModel): number => Number(a.IsTimeout),
			(a: ReportIndexModel): number => sum(a.Reports, r => r.Total),
			(a: ReportIndexModel): number => sum(a.Reports, r => r.Giftout),
			(a: ReportIndexModel): number => a.AccumulatedCoin ?? 0,
			(a: ReportIndexModel): number => a.Reports[a.Reports.length - 1]?.AverageProfit ?? Number.NEGATIVE_INFINITY,
			(a: ReportIndexModel): number => a.Reports[a.Reports.length - 1]?.ProfitRate ?? Number.NEGATIVE_INFINITY,
		];

		protected get min(): Date {
			return threeMonthAgo();
		}

		protected get active(): ReportIndexModel[] {
			return this.sort(this.data?.Kiosks.filter(d => !d.EndTime) ?? []);
		}

		protected get inactive(): ReportIndexModel[] {
			return this.sort(this.data?.Kiosks.filter(d => d.EndTime) ?? []);
		}

		protected get disconnectCount(): number {
			return this.active.filter(k => k.IsTimeout).length;
		}

		private sort(list: ReportIndexModel[]): ReportIndexModel[] {
			return sort(list, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
		}

		@Watch("sorting", { deep: true }) onSorting(v: [number, boolean]): void {
			localStorage.setItem("reportSorting", v[0].toString());
			localStorage.setItem("reportAscending", String(v[1]));
		}

		beforeMount(): void {
			const sorting = localStorage.getItem("reportSorting");
			if(sorting != null) this.sorting[0] = Number(sorting);
			const ascending = localStorage.getItem("reportAscending");
			if(ascending != null) this.sorting[1] = ascending == "true";

			post<TagModel[]>('/api/tag').then(v => this.tags = v);
			let scroll = 0;
			if(cache) {
				this.query = cache.query;
				if(cache.state) {
					scroll = cache.state.scroll;
					this.data = cache.state.data;
					this.summary = this.data.Kiosks.flatMap(d => d.Reports);
					this.time = cache.state.time;
					Vue.nextTick(() => setScroll(scroll));
				} else {
					this.load();
				}
				resetCache();
			} else {
				this.load();
			}
		}

		beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
			if(to.path.startsWith('/report/detail') || to.path.startsWith('/item/detail')) {
				DetailCache.query = {
					start: this.query.start,
					end: this.query.end,
					init: this.query.init,
				};
				cache = {
					query: this.query,
					state: {
						time: this.time!,
						data: this.data!,
						scroll: document.querySelector('main')!.scrollTop,
					},
				};
			} else {
				resetCache();
			}
			next();
		}

		protected async showFilter(): Promise<void> {
			if(await this.$refs.filter.show()) await this.load();
		}

		protected edit(k: ReportIndexModel): void {
			var m = this.$refs.modal;
			m.model = k;
			m.show();
		}

		private async load() {
			this.data = null;
			this.summary = [];
			try {
				this.data = await post<ReportIndexBundle>("/api/Report", toIndexQuery(this.query), TIMEOUT);
				this.summary = this.data.Kiosks.flatMap(d => d.Reports);
				this.update = now();
			} catch(e) {
				this.data = null;
				throw e;
			}
		}

		public add(): void {
			this.$router.push('/device/scan');
		}

		protected excel(): void {
			this.$refs.excel.show();
		}

		protected get giftout(): string {
			if(!this.data) return "";
			let result = 0;
			for(let r of this.data.Kiosks.flatMap(k => k.Reports)) result += r.Giftout;
			return result.toComma();
		}
	}
