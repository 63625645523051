import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=063d38d8&scoped=true&"
import script from "./MainLayout.vue?vue&type=script&lang=ts&"
export * from "./MainLayout.vue?vue&type=script&lang=ts&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&id=063d38d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063d38d8",
  null
  
)

export default component.exports