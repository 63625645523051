
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { Icon as Iconify } from "@iconify/vue2";

	@Component({ components: { Iconify } })
	export default class Icon extends Vue {
		@Prop() public icon!: string;

		protected get iconify(): string | null {
			return this.icon.match(/\b[a-z0-9-]+:[a-z0-9-]+\b/)?.[0];
		}
	}
