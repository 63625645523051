import { Component, Vue } from 'vue-property-decorator';

import * as bootstrap from 'bootstrap';
import './dialog.css';

let opened = false;

export function anyOpen(): boolean {
	return opened;
}

// @ts-ignore
@Component
export default abstract class Dialog<T> extends Vue {
	private modal!: bootstrap.Modal;
	private last: Promise<unknown> = Promise.resolve();
	protected message: string = "";

	mounted(): void {
		this.modal = new bootstrap.Modal(this.$el, { backdrop: 'static' });
	}

	public show(message: string): Promise<T> {
		let current = new Promise<T>(resolve => {
			this.last.then(() => {
				this.message = message;
				this.$el.addEventListener('hidden.bs.modal', () => {
					opened = false;
					resolve(this.value);
				}, { once: true });
				this.modal.show();
				opened = true;
			});
		});
		this.last = current;
		return current;
	}

	protected close(): void {
		this.modal.hide();
	}

	protected abstract readonly value: T;
}
