
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { MultiSelect } from '@progress/kendo-vue-dropdowns';

	import type { FilterOptionModel } from '@common/models/report';

	@Component({ components: { MultiSelect } })
	export default class extends Vue {

		@Prop() public dataItems!: FilterOptionModel[];
		@Prop() public value!: FilterOptionModel[];
	}
