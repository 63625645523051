import { today } from "@common/date";
import type { LeapaItemType } from "./Item";
import type { TerminalPromotionModel } from "./Owner";
import type { MerchantKioskDetailModel } from "@common/models/kiosk";
import type { ReportIndexBaseModel } from "@common/models/report";

export interface ReportIndexBundle {
	Kiosks: ReportIndexModel[];
	Revenue: RevenueModel[];
	AccountId: string;
}

export interface ReportIndexModel extends ReportIndexBaseModel {
	Reports: ReportModel[];
	Promotions: TerminalPromotionModel[];
	/** 後台已經乘以 10 了 */
	AccumulatedCoin?: number;
	AccountId: string;
	ItemName: string | null;
	LeapaItemType: LeapaItemType | null;
	Price?: number;
}

export interface ReportModel {
	StartTime: Date;
	EndTime: Date;
	Price?: number;
	AverageProfit?: number;
	ProfitRate: number;
	Profit: number;
	Coin: number;
	Giftout: number;
	Remote: number;
	Total: number;
}

export interface DailyReport extends ReportModel {
	Date: Date;
}

export interface KioskDetailReport {
	MerchantKiosk: MerchantKioskDetailModel;
	Revenues: RevenueModel[];
	Daily: DailyReport[];
	Reports: ReportModel[];
}

export interface RevenueModel {
	Name: string;
	Fiat: boolean;
	Unit: string;
	Amount?: number;
	Amounts?: number[];
	Value: number;
	Activated: boolean;
}

export interface KioskDailyReport extends DailyReport {
	MerchantKiosk: MerchantKioskDetailModel;
	Revenues: RevenueModel[];
	Transactions: KioskTransaction[];
	Reports: ReportModel[];
}

export enum KioskTransactionType {
	Coin = 0,
	Giftout = 1,
	Remote = 2,
	Payment = 3,
	GiftCoin = 4,
	EventTicket = 5,
	TrialPlay = 6,
}

interface KiostTransactionPayment {
	Name: string;
	Amount: number;
	Unit: string;
}

export interface KioskTransaction {
	Time: Date;
	Amount: number;
	Type: KioskTransactionType;
	Payments: KiostTransactionPayment[];
	IsTestData: boolean;

	/** 前端專用 */
	open?: boolean;
	isTestData?: boolean;
}

export interface ModifyRequest {
	HardwareId: string;
	Events: ModifyItem[];
}

export interface ModifyItem {
	Type: string;
	Time: string;
	Enable: boolean;
}

export interface Query {
	start: Date;
	end: Date;
	itemName: string | null;
	tag: string[];
	exTag: string[];
	types: number[];
	mode: "tag" | "id" | "item" | "type";
	id: string[];
	init: number;
}

function purge(name: string | null): string | null {
	return !name || name.match(/^\s*$/) ? null : name;
}

export function newQuery(): Query {
	return {
		start: today(),
		end: today().addDays(1).addSeconds(-1),
		mode: "tag",
		itemName: null,
		tag: [],
		exTag: [],
		types: [],
		id: [],
		init: 0,
	};
}

export function toIndexQuery(query: Query): Record<string, unknown> {
	let options: Record<string, unknown> = {
		Start: query.start.toIsoTime(),
		End: query.end.toIsoTime(),
		ItemName: purge(query.itemName),
	};
	if(query.mode == 'id') {
		options.Ids = query.id;
	} else {
		options.Tags = query.tag;
		options.ExTags = query.exTag;
		options.Types = query.types;
	}
	return options;
}
