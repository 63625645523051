

	import { Component, Prop } from "vue-property-decorator";
	import LayoutBase from "@common/base/LayoutBase";
	import NavBarLayout from "@common/base/NavBarLayout.vue";
	import BackToTop from "@common/components/widget/BackToTop.vue";

	@Component({
		components: { NavBarLayout, BackToTop },
	})
	export default class MainLayout extends LayoutBase {
		@Prop({ default: 'col py-3 px-4' }) headerClass!: string;
		@Prop({ default: 'col p-2' }) bodyClass!: string;
		@Prop({ default: true }) context?: unknown;
		@Prop() pull?: unknown;
		@Prop(Boolean) noBackToTop?: boolean;
	}
