import Vue from 'vue';
import Vuex from 'vuex';

import { settings } from '@common/communication';
import type { LoginResult } from '@/models/Login';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		hideBack: true,
		hideMenu: false,
		containerClass: "",
		accessToken: null as string | null,
		refreshToken: null as string | null,
	},
	mutations: {
		setHideBack(state, hideBack: boolean) {
			state.hideBack = hideBack;
		},
		setHideMenu(state, hideMenu: boolean) {
			state.hideMenu = hideMenu;
		},
		setContainerClass(state, className: string) {
			state.containerClass = className ?? "bg-white";
		},
		setAccessToken(state, payload?: LoginResult) {
			state.accessToken = payload?.AccessToken ?? null;
			let r = payload?.RefreshToken ?? null;
			state.refreshToken = r;
			if(r) localStorage.setItem("token", r);
			else localStorage.removeItem("token");
		},
	},
	actions: {
	},
	modules: {
	},
});

settings.adapter = {
	getAccessToken: () => store.state.accessToken,
	getRefreshToken: () => store.state.refreshToken,
	setToken: v => store.commit('setAccessToken', v),
};

export default store;
