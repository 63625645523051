
	import { Component, Prop, Vue } from 'vue-property-decorator';

	import * as bootstrap from 'bootstrap';
	import { HOST } from '@/views/device/Add.vue';

	@Component
	export default class extends Vue {
		private modal!: bootstrap.Modal;
		protected code: string = "";

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$el);
		}

		public show(code: string): void {
			this.code = code;
			this.modal.show();
		}

		protected ok(): void {
			this.modal.hide();
			const url = HOST + '/';
			if(navigator.userAgent.includes("iPhone")) location.href = url;
			else this.$router.push("/device/hub/" + encodeURIComponent(url));
		}

		protected toWifi(): void {
			jsInterface.toWifi();
		}
	}
