
	import { Component, Prop, Vue } from "vue-property-decorator";

	function has(obj: object, key: string) {
		return key in obj && (Boolean(obj[key]) || obj[key] === "");
	}

	@Component
	export default class extends Vue {
		@Prop(String) public icon!: string;
		@Prop() public classes!: unknown;
		@Prop() public value!: unknown;
		@Prop(Boolean) public required?: boolean;

		protected get hasDefault(): boolean {
			return Boolean(this.$scopedSlots.default);
		}

		protected get disabled(): boolean {
			return has(this.$attrs, 'disabled') || has(this.$attrs, 'readonly');
		}

		public get attrs(): object {
			return Object.assign({}, this.$attrs, { id: 'ipt' + this._uid });
		}
	}
