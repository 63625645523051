
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import ScrollMain from "./ScrollMain.vue";

	import type { } from 'vuex';
	import type { } from 'vue-router';

	@Component({ components: { ScrollMain } })
	export default class NavBarLayout extends Vue {
		$refs!: {
			nav: HTMLElement;
			main: HTMLElement;
			title: HTMLDivElement;
		};

		@Prop({ default: '' }) containerClass!: string;
		@Prop({ default: '' }) mainClass!: string;
		@Prop({ default: '' }) navigationClass!: string;
		@Prop({ default: '' }) title!: string;
		@Prop(String) backUrl?: string;
		@Prop(Boolean) hideNav!: boolean;
		@Prop(Boolean) hideMenu!: boolean;
		@Prop() pull?: unknown;

		@Inject() public readonly back!: () => void;
		@Inject() public readonly toggleMenu!: () => void;

		protected navClass: string = "";

		created(): void {
			this.$store.commit("setContainerClass", this.containerClass);
			this.$store.commit("setHideMenu", this.hideMenu);
		}

		mounted(): void {
			this.setup(0);
		}

		protected tryBack(): void {
			if(this.backUrl) this.$router.push(this.backUrl);
			else this.back();
		}

		protected setup(scrollTop: number): void {
			let c = "";
			if(scrollTop > 1) c += " nav-shadow";
			this.toggleNavTitle(scrollTop > 0);
			this.navClass = c;
		}

		protected toggleNavTitle(show: boolean): void {
			this.$refs.title?.classList.toggle('show', show);
		}
	}
