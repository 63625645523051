
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class GoRight extends Vue {
		@Prop(Boolean) public small?: boolean;
		@Prop() public to?: string;

		protected click(e: Event): void {
			if(this.to) this.$router.push(this.to);
			this.$emit("click", e);
		}
	}
