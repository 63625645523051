var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonSelect from "./ButtonSelect.vue";
export function sort(data, selector, ascending) {
    const list = data.concat();
    const comparator = (a, b) => {
        var _a, _b;
        let v1 = selector(a), v2 = selector(b);
        let compare = 0;
        if (typeof v1 == "number" && typeof v2 == "number") {
            // 數值比較
            compare = v1 - v2;
        }
        else {
            // 字串比較
            v1 = (_a = v1 === null || v1 === void 0 ? void 0 : v1.toString()) !== null && _a !== void 0 ? _a : "";
            v2 = (_b = v2 === null || v2 === void 0 ? void 0 : v2.toString()) !== null && _b !== void 0 ? _b : "";
            if (v1 > v2)
                compare = 1;
            if (v1 < v2)
                compare = -1;
        }
        return compare * (ascending ? 1 : -1);
    };
    return list.sort(comparator);
}
let Sorter = class Sorter extends Vue {
    toggle() {
        Vue.set(this.sorting, 1, !this.sorting[1]);
    }
};
__decorate([
    Prop()
], Sorter.prototype, "sorting", void 0);
__decorate([
    Prop()
], Sorter.prototype, "options", void 0);
Sorter = __decorate([
    Component({ components: { ButtonSelect } })
], Sorter);
export default Sorter;
