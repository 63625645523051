/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ValidationObserver, ValidationProvider, configure, extend } from "vee-validate";
import Vue from "vue";
import { confirmed, required } from 'vee-validate/dist/rules';
configure({
    classes: {
        invalid: 'error',
    },
});
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
extend('required', Object.assign(Object.assign({}, required), { message: '必填' }));
extend('confirmed', Object.assign(Object.assign({}, confirmed), { message: '兩次輸入的密碼不一致' }));
extend('min', {
    params: ['val'],
    //@ts-ignore
    validate: (value, { val }) => typeof value !== "number" || value >= val,
    message: '至少必須為 {val}',
});
extend('max', {
    params: ['val'],
    //@ts-ignore
    validate: (value, { val }) => typeof value !== "number" || value <= val,
    message: '至多為 {val}',
});
function yearsAgo(year) {
    let now = new Date();
    return new Date(now.getFullYear() - year, now.getMonth(), now.getDate());
}
extend('age', {
    params: ['year'],
    //@ts-ignore
    validate: (value, { year }) => value <= yearsAgo(year),
    message: '您若尚未年滿 {year} 歲，請與客服聯繫。',
});
extend('password', {
    validate: (value) => value.match(/^[a-zA-Z0-9]{8,12}$/),
    message: '密碼必須為 8-12 位英數字',
});
extend('pin', {
    validate: (value) => value.match(/^\d{4}$/),
    message: 'Pin 碼必須為 4 位數字',
});
extend('cellphone', {
    validate: (value) => value.match(/^09\d{8}$/),
    message: '不正確的手機號碼格式',
});
extend('otp', {
    validate: (value) => value.match(/^\d{6}$/),
    message: '驗證碼為六位數字',
});
extend('phone', {
    validate: (value) => value.match(/^[-0-9()+]+$/),
    message: '電話號碼格式錯誤',
});
extend('email', {
    validate: (value) => value.match(/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/),
    message: '電子郵件格式錯誤',
});
extend('uniformId', {
    validate: (value) => CheckIdCardNumber(value) || CheckCompanyNumber(value),
    message: '身份證或統一編號格式錯誤',
});
extend('bankAccount', {
    validate: (value) => value.match(/^\d+$/),
    message: '帳戶僅由數字構成',
});
extend('personId', {
    validate: (value) => CheckIdCardNumber(value),
    message: '身份證格式錯誤',
});
extend('companyId', {
    validate: (value) => CheckCompanyNumber(value),
    message: '統一編號格式錯誤',
});
extend('differentFrom', {
    validate: (value, param) => {
        if ('list' in param)
            return !param.list.includes(value);
        return true;
    },
    params: ['list'],
    message: '不得重複',
});
extend('dateInOrder1', {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate: (value, { a, b }) => a === undefined || b === undefined || a <= b,
    params: ['a', 'b', 'msg'],
    message: '{msg}',
});
extend('dateInOrder2', {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate: (value, { a, b }) => a === undefined || b === undefined || a <= b,
    params: ['a', 'b', 'msg'],
    message: '{msg}',
});
/**
 * 驗身分證
 * @param id 身分證字號
 * @returns 身分字號是否符合格式
 */
function CheckIdCardNumber(id) {
    let format = /^[A-Z]\d{9}$/;
    if (!id.match(format))
        return false;
    id = id.toUpperCase();
    let a = [10, 11, 12, 13, 14, 15, 16, 17, 34, 18, 19, 20, 21, 22, 35, 23, 24, 25, 26, 27, 28, 29, 32, 30, 31, 33];
    let b = [];
    b[1] = a[id.charCodeAt(0) - 65] % 10;
    let c = b[0] = Math.floor(a[id.charCodeAt(0) - 65] / 10);
    for (let i = 1; i <= 9; i++) {
        b[i + 1] = int(id, i);
        c += b[i] * (10 - i);
    }
    return (c % 10 + b[10]) % 10 == 0;
}
/**
 * 驗統一編號
 * @param id 欲驗證的統編
 * @returns 是否符合統編格式
 */
function CheckCompanyNumber(id) {
    // 假設統一編號為 A B C D E F G H
    // A - G 為編號, H 為檢查碼
    // A - G 個別乘上特定倍數, 若乘出來的值為二位數則將十位數和個位數相加
    // A x 1
    // B x 2
    // C x 1
    // D x 2
    // E x 1
    // F x 2
    // G x 4
    // H x 1
    // 最後將所有數值加總, 被 5 整除就為正確（民國 112 年 4 月開始從 10 改成 5）
    // 若上述演算不正確並且 G 為 7 得話, 再加上 1 被 5 整除也為正確
    id = id.trim();
    var monitor = /^\d{8}$/;
    if (!id.match(monitor))
        return false;
    let intTmpVal = [];
    let intTmpSum = 0;
    for (let i = 0; i < 6; i++) {
        // 位置在奇數位置的*2，偶數位置*1，位置計算從0開始
        intTmpVal[i] = OverTen(int(id, i) * (i % 2 == 1 ? 2 : 1));
        intTmpSum += intTmpVal[i];
    }
    // 第6碼*4
    intTmpSum += OverTen(int(id, 6) * 4);
    // 第7碼*1
    intTmpSum += OverTen(int(id, 7));
    if (intTmpSum % 5 == 0)
        return true;
    if (int(id, 6) == 7 && (intTmpSum + 1) % 5 == 0)
        return true;
    return false;
}
function int(s, i) {
    return s.charCodeAt(i) - 48;
}
function OverTen(number) {
    var ones = number % 10;
    var tens = (number - ones) / 10;
    return ones + tens;
}
