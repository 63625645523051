
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import type { MerchantKioskDetailModel } from "@common/models/kiosk";

	const TIMEOUT = 60000;

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public data!: MerchantKioskDetailModel;

		protected async toggle(): Promise<void> {
			const oldState = this.data.LeapaCoinSlotPower;
			try {
				this.data.LeapaCoinSlotPower = oldState == "O" ? "X" : "O";
				await post("/api/Device/ToggleCoinSlot", { Id: this.data.Id }, TIMEOUT);
			} catch(e) {
				this.data.LeapaCoinSlotPower = oldState;
				if(e instanceof Error) {
					this.alert(e.message, "指令失敗", "warning");
				}
			}
		}
	}
