
	import * as bootstrap from 'bootstrap';
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { MultiSelect } from '@progress/kendo-vue-dropdowns';
	import { newQuery } from '@/models/Report';
	import { LeapaItemTypeOptions } from "@/models/Item";
	import FilterKiosk from "./FilterKiosk.vue";

	import type { Query } from '@/models/Report';
	import type { TagModel } from '@common/models/kiosk';
	import type { FilterOptionModel } from '@common/models/report';
	import type DateRange from "@common/components/form/DateRange.vue";

	// Kendo dropdown 同時使用 primitive value 跟 filterable 會有 bug，因此這邊要多一層轉換
	type Temp = Exclude<Query, "id"> & { kiosks: FilterOptionModel[]; };

	@Component({ components: { MultiSelect, FilterKiosk } })
	export default class extends Vue {
		$refs!: {
			range: DateRange;
		};

		@Prop() public query!: Query;
		@Prop() public tags!: TagModel[];
		@Prop(Boolean) public noDate!: boolean;
		@Prop(Boolean) public noInactive?: boolean;
		@Prop(Boolean) public noGoods?: boolean;
		@Prop(Date) public min?: Date;

		protected readonly LeapaItemTypeOptions = LeapaItemTypeOptions;

		private modal!: bootstrap.Modal;
		private value: boolean = false;
		private options: FilterOptionModel[] = [];
		protected filtered: FilterOptionModel[] = [];
		public temp: Temp = {} as Temp;

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$el);
			post<FilterOptionModel[]>("/api/device").then(v => {
				if(this.noInactive) v = v.filter(d => d.IsActive);
				this.options = v;
				this.filtered = v.concat();
				this.options.forEach(o => o.DisplayName = o.Name + " " + o.DisplayCode);
			});
		}

		public show(): Promise<boolean> {
			this.value = false;
			return new Promise<boolean>(resolve => {
				this.copy();
				this.modal.show();
				this.$el.addEventListener('hidden.bs.modal', () => resolve(this.value), { once: true });
			});
		}

		protected get unselected(): FilterOptionModel[] {
			return this.options.filter(k => !this.temp.id.includes(k.KioskId));
		}

		protected onRange(e: [Date, Date]): void {
			if(e.length < 2) return;
			if(!e[1]) e[1] = e[0];
			[this.temp.start, this.temp.end] = e;
		}

		protected onOption(event: Event): void {
			let sel = event.target as HTMLSelectElement;
			let code = sel.value;
			this.temp.id.push(code);
			sel.value = "";
		}

		protected copy(): void {
			Vue.set(this, "temp", {
				start: this.query.start,
				end: this.query.end,
				mode: this.query.mode,
				init: this.query.init,
				tag: this.query.tag.concat(),
				exTag: this.query.exTag.concat(),
				types: this.query.types.concat(),
				itemName: this.query.itemName,
				kiosks: this.options.filter(o => this.query.id.includes(o.KioskId)),
			} as Temp);
			if(!this.noDate) this.$refs.range.reset(this.query.init);
		}

		protected getKiosk(c: string): string {
			let k = this.options.find(e => e.KioskId == c)!;
			if(!k) return "";
			return k.Name;
		}

		protected getKioskCode(c: string): string {
			let k = this.options.find(e => e.KioskId == c)!;
			if(!k) return "";
			return k.DisplayCode;
		}

		protected remove(c: string): void {
			this.temp.id.splice(this.temp.id.indexOf(c), 1);
		}

		protected ok(): void {
			// 去掉與當前篩選模式無關的條件
			if(this.temp.mode != 'tag') {
				this.temp.tag = [];
				this.temp.exTag = [];
			}
			if(this.temp.mode != 'id') this.temp.kiosks = [];
			if(this.temp.mode != 'item') this.temp.itemName = null;
			if(this.temp.mode != 'type') this.temp.types = [];

			Object.assign(this.query, this.temp, {
				kiosks: undefined,
				id: this.temp.kiosks.map(k => k.KioskId),
			});
			if(!this.noDate) this.query.init = this.$refs.range.active;
			this.value = true;
		}

		protected reset(): void {
			Object.assign(this.temp, newQuery());
			if(!this.noDate) this.$refs.range.reset(this.temp.init = 0);
		}

		protected btnClass(o: TagModel): string {
			return this.temp.tag.includes(o.Id) ? "btn-secondary" : "btn-light";
		}

		protected toggle(o: TagModel): void {
			let t = o.Id, i = this.temp.tag.indexOf(t);
			if(i >= 0) this.temp.tag.splice(i, 1);
			else this.temp.tag.push(t);
		}

		protected syncTag(): void {
			this.temp.tag = this.temp.tag.filter(id => !this.temp.exTag.includes(id));
		}

		protected syncExTag(): void {
			this.temp.exTag = this.temp.exTag.filter(id => !this.temp.tag.includes(id));
		}

		protected filterKiosk(event: { filter: { value: string } }): void {
			const text = event.filter.value;
			this.filtered = this.options.filter(r => !text || r.DisplayName.includes(text));
		}
	}
