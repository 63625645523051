
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { Dropdown } from "bootstrap";

	import type { ValidationProvider } from "vee-validate";

	@Component
	export default class DropdownSelect extends Vue {
		@Prop(String) public vid?: string;
		@Prop(String) public icon!: string;
		@Prop() public rules!: string | object;
		@Prop(String) public placeholder?: string;
		@Prop() public value?: unknown;
		@Prop() public height?: string;
		@Prop() public required?: unknown;

		private dropdown: Dropdown = null!;

		mounted(): void {
			this.dropdown = new Dropdown(this.$refs.menu as HTMLDivElement);
		}

		public async valid(): Promise<boolean> {
			let provider = this.$refs.provider as InstanceType<typeof ValidationProvider>;
			let result = await provider.validate();
			return result.valid;
		}

		protected get showPlaceholder(): boolean {
			return Boolean(this.placeholder && !this.value && this.value !== 0);
		}

		protected get isRequired(): boolean {
			if(this.required) return true;
			if(typeof this.rules == "string") return this.rules.includes('required');
			if(typeof this.rules == "object") return 'required' in this.rules;
			return false;
		}

		protected get hasAppend(): boolean {
			return Boolean(this.$scopedSlots.append);
		}
	}
