
	import { Component, Prop, ProvideReactive, Vue } from "vue-property-decorator";
	import { tabKey } from "./Tab.vue";

	import type { TabContext } from "./Tab.vue";

	@Component
	export default class Tabs extends Vue {
		@ProvideReactive(tabKey) public context: TabContext = {
			tabs: [],
			index: 0,
		};

		@Prop() public value?: number;

		mounted() {
			if(typeof (this.value) === "number") this.context.index = this.value;
		}

		protected setIndex(i:number):void {
			this.context.index = i;
			this.$emit("input", i);
		}
	}
