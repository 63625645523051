import ReportIndex from '@/views/report/Index.vue';

import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 營收報表

const routes: Array<RouteConfig> = [

	{
		path: '/report',
        // 這個元件不分開打包到 Chunk 裡面，原因有二：
        // 1. 它基本上是首頁，所以通常會最先載入
        // 2. 它的靜態欄位在別的元件中會被引用到
		component: ReportIndex,
		meta: {
			root: true,
		},
	},
	{
		path: '/report/detail/:id/:date?',
		props: true,
		component: () => import(/* webpackChunkName: "report" */ '@/views/report/Detail.vue'),
	},
	{
		path: '/report/daily/:id/:date',
		props: true,
		component: () => import(/* webpackChunkName: "report" */ '@/views/report/Daily.vue'),
	},
	{
		path: '/report/tag',
		component: () => import(/* webpackChunkName: "report" */ '@/views/report/Tag.vue'),
	},
];

export default routes;
