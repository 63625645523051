
	import { Component, Prop, Vue } from "vue-property-decorator";

	/** 這是自動會調整高度的 `<textarea>` 元件 */
	@Component
	export default class extends Vue {
		@Prop() public value?: string;

		mounted(): void {
			const el = this.$el as HTMLTextAreaElement;
			const min = el.getBoundingClientRect().height;
			const offset = min - el.scrollHeight;
			el.oninput = () => {
				el.style.height = "0";
				el.style.height = Math.max(min, el.scrollHeight + offset) + "px";
			};
		}
	}
