
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	const SECTION_UNIT = 60;
	const TEN = 10;
	const ONE_SECOND = 1000;

	declare global {
		interface CredentialRequestOptions {
			otp: {
				transport: string[];
			}
		}

		interface Credential {
			code: string;
		}
	}

	//let controller: AbortController | undefined;

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop(String) public value!: string;
		@Prop(Boolean) public reg!: boolean;

		_events!: {
			send: Function[];
		};

		//mounted(): void {
		//	if('OTPCredential' in window) {
		//		controller = new AbortController();
		//		navigator.credentials.get({
		//			otp: { transport: ['sms'] },
		//			signal: controller.signal,
		//		})
		//			.then(otp => this.$emit('input', otp.code));
		//	}
		//}

		//destroyed(): void {
		//	if(controller) controller.abort();
		//	controller = undefined;
		//}

		protected time: number = 0;

		protected get formatTime(): string {
			let min = Math.floor(this.time / SECTION_UNIT);
			let sec = this.time % SECTION_UNIT;
			return (min > 0 ? min + ":" : "") + (sec < TEN ? "0" : "") + sec;
		}

		protected async send(): Promise<void> {
			try {
				this.time = -1; // 代表發送中
				let cellphone = await this._events.send[0]();
				if(!cellphone) {
					this.time = 0;
					return;
				}
				await post('/api/Login/RequestOTP', {
					Subject: cellphone,
					Registered: Boolean(this.reg),
				});

				this.time = 120;
				let int = setInterval(() => {
					this.time--;
					if(this.time == 0) clearInterval(int);
				}, ONE_SECOND);

				(this.$el.querySelector("input.form-control") as HTMLInputElement).focus();

			} catch(e: unknown) {
				this.time = 0;
				if(e instanceof Error) this.alert(e.message, "無法傳送驗證碼", "warning");
			}
		}
	}
