import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
	@Prop(String) public title!: string;
	@Prop({ default: '' }) public remark!: string;

	protected get formatTitle(): string {
		let result = this.title?.replace(/\n/g, "<br>");
		if(this.remark) result += `<span class="text-secondary" style="font-size:1rem;">${this.remark}</span>`;
		return result;
	}
}
