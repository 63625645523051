// For Safari < 12.1

if(typeof globalThis === "undefined") {
	Object.defineProperty(window, "globalThis", {
		value: window,
		writable: false,
	});
}

export {};
