import { Enum } from "@common/enum";
import type { MerchantKioskModel, MerchantKioskDetailModel } from "@common/models/kiosk";
import type { ReportModel } from "./Report";
import type { UploadModel } from "@common/models";

export interface KioskItemEditModel {
	Id: string;
	MerchantKioskId: string;
	MerchantKiosk: MerchantKioskModel;
	IsActive: boolean;
	StartTime: Date;
	Name: string;
	Price: number;
	Photo: UploadModel[];
	LeapaItemType: LeapaItemType | null;
}

export interface KioskItemIndexModel {
	KioskItem: KioskItemModel | null;
	MerchantKiosk: MerchantKioskModel
	Report: ReportModel;
	StartTime: Date;
	EndTime: Date | null;
}

export interface KioskItemModel {
	Id: string;
	StartTime: string | null;
	EndTime: string | null;
	Item: ItemModel;
}

export interface ItemModel {
	Photo: string;
	Name: string;
	Unit: string;
	Data: ItemDataModel;
	PurchasePrice: number | null;
}

export interface ItemDataModel {
	LeapaItemType: LeapaItemType | null;
}

export enum LeapaItemType {
	Snack = 10,
	Beverage = 20,
	InflatedPackage = 30,
	StuffedToy = 40,
	Toy = 50,
	Figurine = 60,
	Electronic = 70,
	Necessity = 80,
	Other = 9999,
}

export function leapaItemTypeString(type: LeapaItemType): string {
	switch(type) {
		case LeapaItemType.Snack: return "零食";
		case LeapaItemType.Beverage: return "飲料";
		case LeapaItemType.InflatedPackage: return "空氣包";
		case LeapaItemType.StuffedToy: return "娃娃";
		case LeapaItemType.Toy: return "玩具";
		case LeapaItemType.Figurine: return "公仔";
		case LeapaItemType.Electronic: return "三Ｃ";
		case LeapaItemType.Necessity: return "日用品";
		default: return "其它";
	}
}

export const LeapaItemTypeOptions = Enum.values(LeapaItemType).map(type => ({
	text: leapaItemTypeString(type),
	value: type,
}));
