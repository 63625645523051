
	import { Component } from 'vue-property-decorator';
	import dialogs from '@common/dialogs';

	import Dialog from './dialog';

	@Component
	export default class Alert extends Dialog<void> {

		protected title?: string = "";
		protected icon?: string = "";
		protected btn: string = "";

		protected value: void = undefined;

		mounted(): void {
			// 把自身註冊為 dialogs 模組的 alert 代理
			dialogs.alert = (...args) => this.showIcon(...args);
		}

		public showIcon(message: string, title?: string, icon?: string, btn = '確定'): Promise<void> {
			this.title = title;
			this.icon = icon;
			this.btn = btn;
			return this.show(message.replace(/\n/g, '<br>'));
		}
	}
