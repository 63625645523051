
	import { Component, Prop, Vue } from "vue-property-decorator";
	import Draggable from 'vuedraggable';
	import ImageUploader from "./ImageUploader.vue";
	import UploadImage from "./UploadImage.vue";

	import type { UploadModel } from "@common/models";

	let _pictureUploaderId = 0;

	@Component({ components: { Draggable, ImageUploader, UploadImage } })
	export default class extends Vue {
		@Prop({
			type: Number,
			default: Number.POSITIVE_INFINITY,
		}) public max!: number;

		@Prop(Number) public size?: number;
		@Prop(Boolean) public disabled?: boolean;

		@Prop({
			type: Array,
			default: () => [],
		}) public value!: UploadModel[];

		protected pid: number = _pictureUploaderId++;
		protected dragging: boolean = false;
		protected wait: boolean = false; // 控制動畫顯示

		protected get readonly(): boolean {
			return this.disabled || Boolean(this.$attrs.readonly);
		}

		protected add(data: UploadModel): void {
			this.value.push(data);
			this.$emit('input', this.value.concat());
		}

		protected del(i: number): void {
			this.value.splice(i, 1);
			this.$emit('input', this.value.concat());
		}
	}
