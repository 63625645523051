
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class extends Vue {
		@Prop() public rules!: unknown;
		@Prop() public value?: boolean;
		@Prop({ default: "mb-3" }) public outerClass!: string;
		@Prop({ default: "ml-4 pl-2" }) public innerClass!: string;
	}
