
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	export const tabKey = Symbol("tab");

	export interface TabContext {
		tabs: Tab[];
		index: number;
	}

	@Component
	export default class Tab extends Vue {
		@Inject(tabKey) public context!: TabContext;
		@Prop() public title!: string;

		get cls(): string {
			if(this.context.tabs.indexOf(this) == this.context.index) return "show";
			return "";
		}

		mounted(): void {
			this.context.tabs.push(this);
		}
	}
