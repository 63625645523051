
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { format, pad } from "@common/date";

	function isZero(date: Date): boolean {
		const [h, m, s] = [date.getHours(), date.getMinutes(), date.getSeconds()];
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		return h == 0 && m == 0 && s == 0 || h == 23 && m == 59 && s == 59;
	}

	function minute(date: Date): string {
		return format(date) + " " + pad(date.getHours()) + ":" + pad(date.getMinutes());
	}

	@Component
	export default class RangeDisplay extends Vue {
		@Prop() public start!: Date;
		@Prop() public end!: Date;

		protected get range(): string {
			if(isZero(this.start) && isZero(this.end)) {
				if(this.start.datePart().getTime() == this.end.datePart().getTime()) {
					return format(this.start);
				} else {
					return format(this.start) + " - " + format(this.end);
				}
			} else {
				return minute(this.start) + " - " + minute(this.end);
			}
		}
	}
