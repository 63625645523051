import Login from '@/views/login/Index.vue';

import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 登入

const routes: Array<RouteConfig> = [

    {
		path: '/login',
		component: Login,
		meta: {
			anonymous: true,
			root: true,
		},
		props: route => ({ callback: route.query.callback }),
	},
	{
		path: '/login/register/:username?', // 問號表示選用參數
		props: true,
		component: () => import(/* webpackChunkName: "login" */ '@/views/login/Register.vue'),
		meta: {
			anonymous: true,
		},
	},
	{
		path: '/login/forget',
		component: () => import(/* webpackChunkName: "login" */ '@/views/login/Forget.vue'),
		meta: {
			anonymous: true,
		},
	},
	{
		path: '/login/terms',
		component: () => import(/* webpackChunkName: "login" */ '@/views/login/Terms.vue'),
		meta: {
			anonymous: true,
		},
	},
];

export default routes;
