import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 設備管理

const routes: Array<RouteConfig> = [

    {
		path: '/item',
		component: () => import(/* webpackChunkName: "item" */ '@/views/item/Index.vue'),
		meta: {
			root: true,
		},
	},
	{
		path: '/item/edit/:mkId/:id?',
		props: true,
		component: () => import(/* webpackChunkName: "item" */ '@/views/item/Edit.vue'),
	},
	{
		path: '/item/detail/:id',
		props: true,
		component: () => import(/* webpackChunkName: "item" */ '@/views/item/Detail.vue'),
	},
];

export default routes;
