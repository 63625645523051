

	import { Component, Inject, Prop, Watch } from "vue-property-decorator";
	import { MerchantKioskType } from "@common/models/kiosk";
	import Modal from "@/components/modal";
	import type { TagModel } from "@common/models/kiosk";

	import type { ReportIndexBaseModel } from "@common/models/report";

	@Component
	export default class extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public options!: TagModel[];

		public model: ReportIndexBaseModel | null = null;
		protected tags: string[] = [];
		protected title: string = "";

		@Watch("model") onModel(v: ReportIndexBaseModel): void {
			if(v) {
				this.title = v.Name;
				this.tags = v.Tags.concat();
			}
		}

		public async submit(): Promise<void> {
			if(!this.model) return;
			const model = this.model;
			const old = model.Name, oldTags = model.Tags;
			model.Name = this.title;
			model.Tags = this.tags;

			const title = // 如果是共享帳號而且名稱剛好設定得跟主帳號一樣，那就把共享帳號自訂的名稱清掉、以便跟著主帳號走
				model.Type != MerchantKioskType.admin && this.title == this.model.ActiveMerchantKioskName ?
					null : this.title.trim() || null;

			try {
				await post('/api/Device/Edit', {
					Id: model.Id,
					Name: title,
					Tags: this.tags.map(t => this.options.find(o => o.Name == t)!.Id),
				});
			} catch(e) {
				model.Name = old;
				model.Tags = oldTags;
				await this.alert('儲存失敗，請稍後再試', '', 'warning');
			}
			this.model = null;
		}

		protected btnClass(o: TagModel, i: number): string {
			return (this.tags.includes(o.Name) ? "btn-secondary" : "btn-light") + (i > 0 ? " ml-2" : "");
		}

		protected toggle(o: TagModel): void {
			let t = o.Name, i = this.tags.indexOf(t);
			if(i >= 0) this.tags.splice(i, 1);
			else this.tags.push(t);
		}

		protected resetName(): void {
			if(!this.model) return;
			this.title = this.model.ActiveMerchantKioskName;
		}
	}
