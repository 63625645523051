import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 場主功能

const routes: Array<RouteConfig> = [

	{
		path: '/owner',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/Index.vue'),
		meta: {
			root: true,
		},
	},
	{
		path: '/owner/apply',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/Apply.vue'),
	},
	{
		path: '/owner/outlet/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/Outlet.vue'),
	},
	{
		path: '/owner/match/index/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/match/Index.vue'),
	},
	{
		path: '/owner/match/accept/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/match/Accept.vue'),
	},
	{
		path: '/owner/eventGiveaway',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/eventGiveaway/Index.vue'),
	},
	{
		path: '/owner/eventGiveaway/terminal/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/eventGiveaway/Terminal.vue'),
	},
	{
		path: '/owner/eventGiveaway/detail/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/eventGiveaway/Detail.vue'),
	},
	{
		path: '/owner/eventGiveaway/promotions/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/eventGiveaway/Promotions.vue'),
	},
	{
		path: '/owner/eventGiveaway/edit/:outletId/:id?',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/eventGiveaway/Edit.vue'),
	},

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 集點送
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	{
		path: '/owner/pointGiveaway',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/Index.vue'),
	},
	{
		path: '/owner/pointGiveaway/points',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/points/Index.vue'),
	},
	{
		path: '/owner/pointGiveaway/points/edit/:id?',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/points/Edit.vue'),
	},
	{
		path: '/owner/pointGiveaway/staff',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/staff/Index.vue'),
	},
	{
		path: '/owner/pointGiveaway/staff/edit/:id?',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/staff/Edit.vue'),
	},
	{
		path: '/owner/pointGiveaway/promotions/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/Promotions.vue'),
	},
	{
		path: '/owner/pointGiveaway/edit/:outletId/:id?',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/Edit.vue'),
	},
	{
		path: '/owner/pointGiveaway/detail/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/pointGiveaway/Detail.vue'),
	},

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 分享送
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	{
		path: '/owner/shareGiveaway',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/shareGiveaway/Index.vue'),
	},
	{
		path: '/owner/shareGiveaway/promotions/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/shareGiveaway/Promotions.vue'),
	},
	{
		path: '/owner/shareGiveaway/detail/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/shareGiveaway/Detail.vue'),
	},
	{
		path: '/owner/shareGiveaway/edit/:outletId/:id?',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/shareGiveaway/Edit.vue'),
	},

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 發送人
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	{
		path: '/owner/distributer',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/distributer/Index.vue'),
	},
	{
		path: '/owner/distributer/add',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/distributer/Add.vue'),
	},
	{
		path: '/owner/distributer/remove',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/distributer/Remove.vue'),
	},
	{
		path: '/owner/distributer/detail/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/distributer/Detail.vue'),
	},
	{
		path: '/owner/distributer/record/:id?',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/distributer/Record.vue'),
	},
	{
		path: '/owner/distributer/promotionRecord/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/distributer/PromotionRecord.vue'),
	},

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 一番賞
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	{
		path: '/owner/ichibankuji',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/ichibankuji/Index.vue'),
	},
	{
		path: '/owner/ichibankuji/edit',
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/ichibankuji/Edit.vue'),
	},
	{
		path: '/owner/ichibankuji/detail/:id',
		props: true,
		component: () => import(/* webpackChunkName: "owner" */ '@/views/owner/ichibankuji/Detail.vue'),
	},
];

export default routes;
