
	import { Component, Prop, Provide, Vue } from "vue-property-decorator";

	const trigger = 100;
	const speed = 1;
	const init = 0;
	const rotation = 1.5;

	@Component
	export default class ScrollMain extends Vue {
		@Prop() public containerClass?: string;
		@Prop() public pull?: Function;

		$refs!: {
			main: HTMLDivElement;
			reload: HTMLDivElement;
			spin: HTMLSpanElement;
		};

		private y: number = 0;
		private pullState: number = 0;
		private showBackToTop: boolean = false;
		private ctrl: AbortController = new AbortController();

		mounted(): void {
			let opt = { signal: this.ctrl.signal } as AddEventListenerOptions;
			document.addEventListener('touchmove', e => this.move(e), opt);
			document.addEventListener('touchend', e => this.end(e), opt);
			document.addEventListener('touchcancel', e => this.end(e), opt);
		}

		beforeDestroy(): void {
			this.ctrl.abort();
		}

		@Provide() public backToTop(): void {
			this.$refs.main.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}

		protected start(e: TouchEvent): void {
			if(document.querySelector(".modal.show")) return; // 有對話方塊開啟時停用 pull 功能
			this.y = e.touches[0].clientY;
			if(this.$el.scrollTop <= 0 && this.pull) this.pullState = 1;
			let style = this.$refs.reload.style;
			style.transition = "none";
		}

		protected move(e: TouchEvent): void {
			if(!this.pullState) return;
			let y = e.touches[0].clientY;
			if(y < this.y && this.pullState == 1) this.pullState = 0;
			if(y > this.y && this.pullState) {
				(this.$el as HTMLDivElement).style.overflowY = 'hidden';
				this.pullState = 2;
				let delta = (y - this.y) * speed + init;
				y = Math.min(delta, trigger);
				this.$refs.reload.style.opacity = Math.max(y / trigger, 0).toString();
				this.$refs.reload.style.top = y + "px";
				this.$refs.spin.style.transform = "rotate(" + delta * rotation + "deg)";
			}
		}

		protected end(e: TouchEvent): void {
			this.reset();
			if(this.pullState) {
				let y = e.changedTouches[0].clientY;
				if((y - this.y) * speed + init >= trigger) this.pull!();
			}
			this.pullState = 0;
		}

		private reset(): void {
			let style = this.$refs.reload.style;
			style.transition = "all 0.2s";
			style.opacity = "0";
			style.top = init + "px";
			(this.$el as HTMLDivElement).style.overflowY = 'scroll';
		}

		protected scroll(e: Event): void {
			var top = (e.target as HTMLElement).scrollTop;
			this.showBackToTop = top > trigger;
			this.$emit('scroll', top);
		}
	}
