import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 設備管理

const routes: Array<RouteConfig> = [

    {
		path: '/device/add',
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/Add.vue'),
	},
	{
		path: '/device/scan',
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/Scan.vue'),
	},
	{
		path: '/device/setup',
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/Setup.vue'),
	},
	{
		path: '/device/hub/:url',
		props: true,
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/Hub.vue'),
	},
	{
		path: '/device/activate',
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/Activate.vue'),
	},
];

export default routes;
