/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Component, Vue, Watch } from "vue-property-decorator";
import { now } from "@common/date";

@Component export default class extends Vue {

	protected time: string | null = null;
	protected update: Date = now();
	private inte: number = 0;

	created(): void {
		this.inte = window.setInterval(() => this.updateTime(), 1000);
	}

	beforeDestroy(): void {
		clearInterval(this.inte);
	}

	@Watch("update") private updateTime(): void {
		this.time = this.getTime();
	}

	private getTime(): string {
		let diff = (now().getTime() - this.update.getTime()) / 1000;
		if(diff < 60) return "剛剛";
		diff /= 60;
		if(diff < 60) return Math.floor(diff) + " 分鐘前";
		diff /= 60;
		if(diff < 24) return Math.floor(diff) + " 小時前";
		return Math.floor(diff / 24) + " 天前";
	}
}
