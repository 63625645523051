
	import { Component, Prop, Vue } from "vue-property-decorator";

	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-tw';
	import DatePicker from 'vue2-datepicker';

	import type { ValidationProvider } from "vee-validate";

	@Component({
		components: { DatePicker },
	})
	export default class extends Vue {
		@Prop(String) public vid?: string;
		@Prop(String) public icon!: string;
		@Prop() public rules!: string | object;
		@Prop(String) public min?: string;
		@Prop(String) public max?: string;
		@Prop(Date) public init?: Date;
		@Prop(String) public placeholder?: string;
		@Prop(Function) public disabledDate?: (date: Date) => boolean;
		@Prop({ default: 'text' }) public type!: string;
		@Prop() public value?: unknown;
		@Prop(Boolean) public small?: boolean;
		@Prop(String) public inputClass?: string;

		private showPassword: boolean = false;

		public async valid(): Promise<boolean> {
			let provider = this.$refs.provider as InstanceType<typeof ValidationProvider>;
			let result = await provider.validate();
			return result.valid;
		}

		protected get showPlaceholder(): boolean {
			return this.type == 'select' && Boolean(this.placeholder && !this.value && this.value !== 0);
		}

		protected get required(): boolean {
			if(typeof this.rules == "string") return this.rules.includes('required');
			if(typeof this.rules == "object") return 'required' in this.rules;
			return false;
		}

		protected get formateDate(): string {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			if(this.value instanceof Date) return this.value.toISOString().substr(0, 10);
			if(this.value instanceof String) return this.value.replace(/-/g, ".") ?? "";
			return "";
		}

		protected get formatTime(): string {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			if(this.value instanceof Date) return new Date(this.value.getTime() + 8 * 60 * 60 * 1000).toISOString().substr(0, 16);
			if(this.value instanceof String) return this.value.replace(/-/g, ".") ?? "";
			return "";
		}

		protected dateChange(d: string): void {
			let date = Date.parse(d);
			if(!isNaN(date)) this.$emit('input', new Date(date));
			else this.$emit('input', null);
		}

		protected get renderType(): string {
			return this.showPassword ? "text" : this.type;
		}

		protected get hasAppend(): boolean {
			return Boolean(this.$scopedSlots.append);
		}

		protected onInput(event: Event): void {
			var target = event.target as HTMLInputElement;
			var value: string | number = target.value;
			if(this.renderType == 'number') {
				// iOS 上不會擋輸入文字，這邊加以處理
				var num = Number(value);
				if(isNaN(num)) {
					value = String(this.value);
					target.value = value;
				} else {
					value = num;
				}
			}
			this.$emit('input', value);
		}
	}
